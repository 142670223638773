@import url('https://fonts.googleapis.com/css?family=Montserrat:900i&display=swap');

body {
    background: yellow;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

input {
    font-family: 'Montserrat', sans-serif;
}


.Spinner {
    position: fixed;
   left:50%;
    top:50%;
    transform: translate(-50%, -50%);
}



:focus-visible {
    outline-color: black;
}

h1 {
    letter-spacing: 0.1em;
    font-size: 60px;
    max-width: 900px;
    text-shadow: 8px 8px #2066c7, 0px 0px #000000;
    transform: rotate(-5deg);
}

.App {
    text-align: center;
    margin: 0 auto;

}

.Error {
    color: red;
    margin-top: 3rem;
}

.Form {
    padding: 2.4rem;
    max-width: 900px;
    margin: 0 auto;
}

.FormWrapper {
    border: 1px solid black;
    padding: 2.4rem;
    box-shadow: 8px 8px #2066c7, 0px 0px #000000;
}

.FormContainer form {
    display: flex;
    justify-content: center;
}

.FormContainer input {
    padding: .5rem;
    border: 0;

}

.UrlInput {
    width: 100%;
    max-width: 375px;
    border: 1px solid black !important;
    outline: 0;
}

.InputSubmit {
    color: #fff;
    background: black;
    min-width: 80px;
    font-weight: 700;
    font-style: italic;
    cursor: pointer;
}



.UploadInput::-webkit-file-upload-button {
    visibility: hidden;
}

.UploadInput::before {
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}

.UploadInput:hover::before {
    border-color: black;
}

.UploadInput:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.UploadInputWrapper {
    margin: 2.4rem 0;
}

.UploadInputWrapper input {
    font-size: 0;
    opacity: 0;
    width: 0;
    height: 0;
    cursor: pointer;
}

.UploadLabel {
    background: black;
    color: #fff;
    padding: 1.2rem 2.4rem;
    display: inline-block;
    cursor: pointer;

}

.ResponseData img {
    max-width: 100%;
    height: auto;
}

.ResponseData ul {
    list-style-type: none;
    padding: 2.4rem;
    background: #fff;
    text-align: left;

}
@media (max-width: 768px) {
    h1 {

    }
}

@media (min-width: 768px) {
    h1 {
        font-size: 100px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 3px;
        -webkit-text-stroke-color: white;
        text-shadow: 8px 8px #2066c7, 20px 20px #000000;
        margin:4rem auto 0 auto;
        padding: 0 1.2rem;
    }

    .Form {
        margin-top: 5rem;
    }
}